import {createMd5Token} from "../utils/text";

export default class Creative {
  static STATUS_FAILED = -1;
  static STATUS_PENDING = 0;
  static STATUS_PROCESSED = 1;

  static EXTRA_HEAD_TEMPLATE_ID = "head_template_id";
  static EXTRA_COMBO_STEPS = "combo_steps";
  static EXTRA_BACKGROUNG = "background";

  _data = {};

  constructor() {
    this._data.id = createMd5Token(Date.now());
    this._data.file = null;
    this._data.status = Creative.STATUS_PENDING;
    this._data.alias = "";
    this._data.templateId = 0;
    this._data.handler = "";
    this._data.error = null;
    this._data.extra = {};
    this._data.tasks = {};
    this._data.result = null;
  }

  setFile(file) {
    this._data.file = file;
    return this;
  }

  setTemplateId(templateId) {
    this._data.templateId = templateId;
    return this;
  }

  setAlias(alias) {
    this._data.alias = alias;
    return this;
  }

  setHandler(handler) {
    this._data.handler = handler;
    return this;
  }

  markAsProcessed(result) {
    this._data.result = result;
    this._data.status = Creative.STATUS_PROCESSED;
    return this;
  }

  markAsFailed(error) {
    this._data.error = error;
    this._data.status = Creative.STATUS_FAILED;
    return this;
  }

  get id() {
    return this._data.id;
  }

  get file() {
    return this._data.file;
  }

  get templateId() {
    return this._data.templateId;
  }

  get handler() {
    return this._data.handler;
  }

  get alias() {
    return this._data.alias;
  }

  get error() {
    return this._data.error;
  }

  get isProcessed() {
    return this._data.status === Creative.STATUS_PROCESSED;
  }

  get isFinished() {
    return this._data.status === Creative.STATUS_PROCESSED
        || this._data.status === Creative.STATUS_FAILED;
  }

  get isFailed() {
    return this._data.status === Creative.STATUS_FAILED;
  }

  get isPending() {
    return this._data.status === Creative.STATUS_PENDING;
  }

  get result() {
    return this._data.result;
  }

  setExtra(key, value) {
    this._data.extra[key] = value;
    return this;
  }

  hasExtra(key) {
    return this._data.extra.hasOwnProperty(key);
  }

  getExtra(key, defaultValue) {
    return this._data.extra[key] || defaultValue;
  }

  getTask(name) {
    return this._data.tasks[name] || null;
  }

  setTask(name, task) {
    this._data.tasks[name] = task;
    return this;
  }
}
