export default class Processing {
  _file = null;
  _creatives = [];
  _language = "en";
  _gender = null;
  _storeParams = {};

  get file() {
    return this._file;
  }

  get gender() {
    return this._gender;
  }

  get creatives() {
    return this._creatives;
  }

  get storeParams() {
    return this._storeParams;
  }

  setFile(file) {
    this._file = file;
  }

  setGender(gender) {
    this._gender = gender;
  }

  addCreative(creative) {
    this._creatives.push(creative);
  }

  removeCreative(creative) {
    this._creatives = this._creatives.filter(c=> c.id !== creative.id);
  }

  setStoreParams(params) {
    this._storeParams = params;
  }
}