import React from "react";
import i18n from "../../i18n";
import AppContext from "../../contexts/AppContext";
import Loader from "../components/Loader";
import * as api from "../../utils/api";
import routes from "../../routes";
import ErrorView from "../components/ErrorView";

export default class UploadPage extends React.Component {
  state = {
    isLoading: true,
    error: null,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.file) {
      this.createFile(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  handleFileSelected = (file) => {
    this.setState({
      isLoading: true,
    }, () => {
      this.createFile(file);
    });
  };

  createFile = (file) => {
    api.createFile(file)
      .then(this.handleFileUploaded)
      .catch((error) => {
        this.setState({
          isLoading: false,
          error,
        });
      });
  };

  handleFileUploaded = (file) => {
    this.props.history.replace({
      pathname: routes.PROCESSING,
      state: {...this.props.location.state, ...{file}}
    });
  }

  render() {
    if (this.state.isLoading) {
      return <Loader
          message={i18n.t("loader_processing")} />;
    }

    if (this.state.error) {
      return <ErrorView
          error={this.state.error}
          onFileSelected={this.handleFileSelected} />;
    }
  }
}

UploadPage.contextType = AppContext;
