import {handlersNames} from "./handlers";

const genders = {
  male: "male",
  female: "female",
};

const qrCodeStyles = [4, 9, 6];

const templates = [
  // {id: 1, steps: [4427, 4747], handler: handlersNames.COMBO},
  {
    id: 4747,
    handler: handlersNames.SIMPLE,
    qrCodeStyles,
  }, {
    id: 4427,
    handler: handlersNames.SIMPLE,
    qrCodeStyles,
  }, {
    id: 4428,
    handler: handlersNames.SIMPLE,
    qrCodeStyles,
  }, {
    id: 5498,
    handler: handlersNames.SIMPLE,
    qrCodeStyles,
  }, {
    isAlways: true,
    id: 4691,
    handler: handlersNames.BACKGROUNG,
    background: "https://qrcode.test.photo-cdn.net/assets/images/background_yellow.png",
    qrCodeStyles: [6],
  }, {
    isAlways: true,
    id: 4691,
    handler: handlersNames.BACKGROUNG,
    background: "https://qrcode.test.photo-cdn.net/assets/img/1978/bg.jpg",
    qrCodeStyles: [9],
  }, {
    id: 5938,
    handler: handlersNames.SIMPSONS,
    qrCodeStyles,
  }, {
    id: 5303,
    handler: handlersNames.DISNEY,
    qrCodeStyles,
  }, {
    isAlways: true,
    id: 6536,
    handler: handlersNames.TEST_1,
    qrCodeStyles: [9],
  },{
    isAlways: true,
    id: 6537,
    handler: handlersNames.TEST_1,
    qrCodeStyles: [9],
  },
];

export default {
  genders,
  templates,
}

