import React from "react";
import AppContext from "../../contexts/AppContext";
import i18n from "../../i18n";
import Loader from "../components/Loader";
import routes from "../../routes";
import ErrorView from "../components/ErrorView";
import DumbImageView from "../components/DumbImageView";
import FileChooseButton from "../components/FileChooseButton";

export default class ResultPage extends React.Component {

  state = {
    isLoading: true,
    isProcessing: true,
    error: null,
    processing: null,
    results: [],
    selectedGroup: null,
    selectedResult: null,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.processing && this.props.location.state.processing.creatives) {
      this.setState({
        processing: this.props.location.state.processing,
      }, () => {
        this.checkProcessing();
      });
    } else {
      this.props.history.replace({
        pathname: routes.INDEX,
      });
    }
  }

  componentWillUnmount() {}

  handleFileSelected = (file) => {
    this.props.history.replace({
      pathname: routes.INDEX,
    });
  };

  checkProcessing = () => {
    const processing = this.state.processing;

    const creatives = processing.creatives.filter((c) => c.alias === "creative");
    const processedCreatives = creatives.filter((c) => c.isProcessed);
    const finishedCreatives = creatives.filter((c) => c.isFinished);

    const results = [];

    processedCreatives.map((creative) => {
      const templateTask = creative.getTask("template");

      const r2 = [];
      creative.result.result.map((result) => {
        r2.push({...result, template_result_url: templateTask.resultUrl});
      });

      results.push({
        id: templateTask.resultUrl,
        template_result_url: templateTask.resultUrl,
        variants: r2,
      });
    });

    this.setState({
      isLoading: processedCreatives.length < 1,
      isProcessing: creatives.length !== finishedCreatives.length,
      results: results,
      selectedGroup: this.state.selectedGroup || results[0],
      selectedResult: this.state.selectedResult || results[0].variants[0] || null,
    });

    if (finishedCreatives.length < creatives.length) {
      setTimeout(this.checkProcessing, 250);
    }
  };

  handleGroupSelect = (group) => this.setState({
    selectedGroup: group,
    selectedResult: group.variants[0],
  });

  handleResultSelect = (result) => this.setState({
    selectedResult: result,
  });

  render() {
    if (this.state.isLoading) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onFileSelected={this.handleFileSelected} />;
    }

    const selectedGroup = this.state.selectedGroup && this.state.results.find((g) => {
      return g.id === this.state.selectedGroup.id;
    });

    return <section className="sticker-pack-page">
      <div>LINK: {this.state.processing.storeParams.code_content}</div>
      {this.state.isProcessing && <div>PROCESSING...</div>}

      <div className="templates-container">
      {this.state.results.map((group) =>
        <ChoiceView2
          key={group.id}
          group={group}
          isActive={this.state.selectedGroup && this.state.selectedGroup.id === group.id}
          onClick={this.handleGroupSelect}
        />)}
      </div>

      <div className="templates-container">
        {selectedGroup.variants.map((result) => <ChoiceView
          key={result.file.id}
          result={result}
          isActive={this.state.selectedResult && this.state.selectedResult.file.id === result.file.id}
          onClick={this.handleResultSelect}
        />)}
      </div>

      <div className="collage-container">
        {this.state.selectedResult && <ResultView result={this.state.selectedResult} />}
      </div>

      <div className="btn-container">
        <button
          className="btn-upload"
          onClick={() => this.props.history.replace(routes.INDEX)}>
          back to index
        </button>
      </div>
    </section>;
  }
}

ResultPage.contextType = AppContext;

function ResultView({result}) {
  const isValid = result.zxing_is_valid.toString();

  return <>
    <div className="image-view-container">
      <DumbImageView
        className="collage"
        image={result.file.url}
      />
    </div>

    <DumbImageView
      className="photolab"
      image={result.template_result_url}
    />

    <p className="collage-text">
      <a href={result.file.url} target="_blank" rel="noopener noreferrer">{result.file.url}</a>
    </p>
    <p className="collage-text">is_valid: {isValid}</p>
    <p className="collage-text">code_generate_sec: {result.code_generate_sec}</p>
    <p className="collage-text">code_validate_sec: {result.code_validate_sec}</p>
    <p className="collage-text">
      <a href={result.file.url} target="_blank" rel="noopener noreferrer" download>download</a>
    </p>
  </>;
}


function ChoiceView({result, isActive, onClick}) {
  const classNames = [
    "btn-choice-template",
  ];

  const styles = {
    backgroundImage: `url(${result.file.url})`
  };

  isActive && classNames.push("active");

  return <button
      className={classNames.join(" ")}
      style={styles}
      onClick={() => onClick(result)} />;
}

function ChoiceView2({group, isActive, onClick}) {
  const classNames = [
    "btn-choice-template",
  ];

  const styles = {
    backgroundImage: `url(${group.template_result_url})`
  };

  isActive && classNames.push("active");

  return <button
      className={classNames.join(" ")}
      style={styles}
      onClick={() => onClick(group)} />;
}