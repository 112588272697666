import React from "react";
import i18n from "../../i18n";
import AppContext from "../../contexts/AppContext";
import Loader from "../components/Loader";
import FileChooseButton from "../components/FileChooseButton";
import routes from "../../routes";
import ErrorView from "../components/ErrorView";
import {generateHash} from "../../utils/text";
import crypto from "crypto";

export default class IndexPage extends React.Component {

  state = {
    isLoading: false,
    error: null,
    link: "https://pho.to/qr/" + generateHash().substring(0, 6),
    isAll: false,
    qrCodeVersion: 3,
  };

  componentDidMount() {}

  handleFileSelected = (file) => {
    this.props.history.replace({
      pathname: routes.UPLOAD,
      state: {
        file: file,
        link: this.state.link,
        isAll: this.state.isAll,
        qrCodeVersion: this.state.qrCodeVersion,
      },
    });
  };

  handleEthereumClick = (e) => {
    const address = "0x" + crypto.randomBytes(20).toString("hex");

    this.setState({
      link: address,
      qrCodeVersion: 5,
    })
  };

  handleCovidRussiaClick = (e) => {
    // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
    const uuidv4 = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });

    const address = "https://www.gosuslugi.ru/vaccine/cert/verify/" + uuidv4;

    this.setState({
      link: address,
      qrCodeVersion: 8,
    })
  };

  handleEurounionClick = (e) => {
    this.setState({
      link: "HC1:6BF+70790T9WJWG.FKY*4GO0.O1CV2 O5 N2FBBRW1*70HS8WY04AC*WIFN0AHCD8KD97TK0F90KECTHGWJC0FDC:5AIA%G7X+AQB9746HS80:54IBQF60R6$A80X6S1BTYACG6M+9XG8KIAWNA91AY%67092L4WJCT3EHS8XJC$+DXJCCWENF6OF63W5NW6WF6%JC QE/IAYJC5LEW34U3ET7DXC9 QE-ED8%E.JCBECB1A-:8$96646AL60A60S6Q$D.UDRYA 96NF6L/5QW6307KQEPD09WEQDD+Q6TW6FA7C466KCN9E%961A6DL6FA7D46JPCT3E5JDLA7$Q6E464W5TG6..DX%DZJC6/DTZ9 QE5$CB$DA/D JC1/D3Z8WED1ECW.CCWE.Y92OAGY8MY9L+9MPCG/D5 C5IA5N9$PC5$CUZCY$5Y$527B+A4KZNQG5TKOWWD9FL%I8U$F7O2IBM85CWOC%LEZU4R/BXHDAHN 11$CA5MRI:AONFN7091K9FKIGIY%VWSSSU9%01FO2*FTPQ3C3F",
      qrCodeVersion: "auto",
    })
  };

  render() {
    if (this.state.isLoading) {
      return <Loader
          message={i18n.t("loader_processing")} />;
    }

    if (this.state.error) {
      return <ErrorView
          error={this.state.error}
          onFileSelected={this.handleFileSelected} />;
    }

    return <IndexView
        link={this.state.link}
        onLinkChange={(e) => this.setState({link: e.target.value})}
        qrCodeVersion={this.state.qrCodeVersion}
        onQrCodeVersionChange={(e) => this.setState({qrCodeVersion: e.target.value})}
        isAll={this.state.isAll}
        onIsAllChange={(e) => this.setState({isAll: !this.state.isAll})}
        onEthereumClick={this.handleEthereumClick}
        onCovidRussiaClick={this.handleCovidRussiaClick}
        onEurounionClick={this.handleEurounionClick}
        onFileSelected={this.handleFileSelected}
    />;
  }
}

IndexPage.contextType = AppContext;

function IndexView({onCovidRussiaClick, onEurounionClick, qrCodeVersion, isAll, onIsAllChange, link, onLinkChange, onFileSelected, onQrCodeVersionChange, onEthereumClick}) {
  return <section className="index-page">
    <div className="container">
      <div className="btn-container">
        <textarea value={link} onChange={onLinkChange} cols={50} rows={4} />
      </div>
      <div className="btn-container">
        QR version
        <label><input type="radio" name="qrcodechange" value={3} checked={qrCodeVersion == 3} onChange={onQrCodeVersionChange} /> 3</label>
        <label><input type="radio" name="qrcodechange" value={5} checked={qrCodeVersion == 5} onChange={onQrCodeVersionChange} /> 5</label>
        <label><input type="radio" name="qrcodechange" value={8} checked={qrCodeVersion == 8} onChange={onQrCodeVersionChange} /> 8</label>
        <label><input type="radio" name="qrcodechange" value={"auto"} checked={qrCodeVersion == "auto"} onChange={onQrCodeVersionChange} /> AUTO</label>
      </div>
      <div className="btn-container">
        <label>
          <input type="checkbox" checked={isAll} onChange={onIsAllChange} />
          all
        </label>
      </div>
      <div className="btn-container">
        <input type="button" value="ethereum address" onClick={onEthereumClick} />
      </div>
      <div className="btn-container">
        <input type="button" value="covid19 russian" onClick={onCovidRussiaClick} />
      </div>
      <div className="btn-container">
        <input type="button" value="covid19 european union" onClick={onEurounionClick} />
      </div>
      <div className="btn-container">
        <FileChooseButton
          className="btn-upload"
          onFileSelected={onFileSelected}>
          <span dangerouslySetInnerHTML={{__html: i18n.t("index_page__select_photo_button")}} />
        </FileChooseButton>
      </div>
    </div>
  </section>;
}

