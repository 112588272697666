import React from "react";
import AppContext from "../../contexts/AppContext";
import i18n from "../../i18n";
import routes from "../../routes";
import Loader from "../components/Loader";
import {correctionLevels, defaultLink, getHandlerByName, handlersNames} from "../../photolab/handlers";
import Processing from "../../photolab/Processing";
import Creative from "../../photolab/Creative";
import config from "../../photolab/config";
import ErrorView from "../components/ErrorView";

export default class ProcessingPage extends React.Component {
  state = {
    isLoading: true,
    error: null,
    processing: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.file) {
      this.startProcessing(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {}

  handleFileSelected = (file) => {
    this.props.history.replace({
      pathname: routes.UPLOAD,
      state: {file}
    });
  };

  startProcessing = (file) => {
    const storeParams = {};

    storeParams.qrcode_version = this.props.location.state.qrCodeVersion;
    storeParams.isAll = this.props.location.state.isAll;
    storeParams.code_content = this.props.location.state.link;

    if (storeParams.isAll) {
      storeParams.qrcode_styles = "all";
    }

    const processing = new Processing();
    processing.setFile(file);
    processing.setStoreParams(storeParams);

    this.setState({processing}, this.handleHeadTask);
  }

  // handleGenderTask = () => {
  //   const processing = this.state.processing;
  //
  //   const creative = new Creative()
  //     .setTemplateId("gender_classifier")
  //     .setFile(processing.file)
  //     .setHandler(handlersNames.TEMPLATE)
  //     .setAlias("gender");
  //
  //   processing.addCreative(creative);
  //
  //   const handler = getHandlerByName(creative.handler);
  //   handler(processing, creative)
  //     .then((creative) => {
  //       processing.setGender(creative.result.gender.value);
  //
  //       this.setState({processing}, this.handleHeadTask);
  //     })
  //     .catch((creative) => {
  //       processing.setGender(config.genders.male);
  //
  //       this.setState({processing}, this.handleHeadTask);
  //     });
  // }

  handleHeadTask = () => {
    const processing = this.state.processing;
    const templateId = 5970;

    const creative = new Creative()
      .setTemplateId(templateId)
      .setFile(processing.file)
      .setHandler(handlersNames.TEMPLATE)
      .setAlias("head");

    processing.addCreative(creative);

    this.setState({processing});

    const handler = getHandlerByName(creative.handler);
    handler(processing, creative)
      .then((creative) => {
        this.handleCreativesTasks();
      })
      .catch((creative) => {
        this.setState({
          isLoading: false,
          error: creative.error,
        });
      });
  }

  handleCreativesTasks = () => {
    const processing = this.state.processing;
    let templates = config.templates.filter((t) => !!t.isAlways);

    console.log(processing.storeParams.isAll, "111111111");

    if (processing.storeParams.isAll) {
      templates = config.templates;
    }

    templates.map((t) => {
      const creative = new Creative()
        .setTemplateId(t.id)
        .setFile(processing.file)
        .setHandler(t.handler)
        .setAlias("creative")
        .setExtra(Creative.EXTRA_HEAD_TEMPLATE_ID, 5970)
        .setExtra(Creative.EXTRA_COMBO_STEPS, t.steps)
        .setExtra(Creative.EXTRA_BACKGROUNG, t.background)
        .setExtra("qrCodeStyles", t.qrCodeStyles);

      processing.addCreative(creative);

      const handler = getHandlerByName(creative.handler);
      handler(processing, creative);
    });

    this.setState({processing}, this.checkProcessing);
  }

  checkProcessing = () => {
    const processing = this.state.processing;
    const templates = config.templates;

    const creatives = processing.creatives.filter((c) => c.alias === "creative");
    const processedCreatives = creatives.filter((c) => c.isProcessed);
    const failedCreatives = creatives.filter((c) => c.isFailed);

    if (failedCreatives.length === templates.length) {
      const firstFailedCreative = failedCreatives[0];

      this.setState({
        isLoading: false,
        error: firstFailedCreative.error,
      });

      return;
    }

    if (processedCreatives.length > 1) {
      this.props.history.replace({
        pathname: routes.RESULT,
        state: {processing}
      });
    } else {
      setTimeout(this.checkProcessing, 250);
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loader
          message={i18n.t("loader_processing")}/>;
    }

    if (this.state.error) {
      return <ErrorView
          error={this.state.error}
          onFileSelected={this.handleFileSelected} />;
    }
  }
}

ProcessingPage.contextType = AppContext;
